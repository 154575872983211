<template>
  <div class="search-options">
    <div>
      <div class="search-section"
        v-if="value.api !== undefined && !isStaff"
      >
        <div class="search-option mt-4">
          <div>{{value.api === 'internal' ? 'Results from our extended catalog are hidden.' : 'Some results are hidden.'}}</div>
          <SearchShowAllApis class="btn btn-primary btn-sm mt-1"/>
        </div>
      </div>
      <div class="search-section" v-if="user">
        <div class="mt-4">
          <div class="search-option">
            <div class="custom-control custom-switch">
                <input
                  id="filter-favorites"
                  type="checkbox"
                  v-model="selected.favorites"
                  :false-value="undefined"
                  @change="onInput"
                  class="custom-control-input"
                >
                <label class="custom-control-label" for="filter-favorites">
                  Favorites
                </label>
            </div>
          </div>
        </div>
      </div>
      <div class="search-section">
        <Collapser>
          <div class="search-title" slot="header">
            Type
          </div>
          <FilterCheckboxes
            class="search-option"
            v-model="selected.image_type"
            @input="onInput"
            :options="image_types"
            :multiple="true"
          ></FilterCheckboxes>
        </Collapser>
      </div>
      <div class="search-section">
        <Collapser>
          <div class="search-title" slot="header">
            People
          </div>
          <FilterCheckboxes
            class="search-option"
            v-model="selected.people"
            @input="onInput"
            :options="peoples"
            :multiple="true"
          ></FilterCheckboxes>
        </Collapser>
      </div>
      <div class="search-section">
        <Collapser>
          <div class="search-title" slot="header">
            Color
          </div>
          <FilterColorCheckboxes
            class="search-option"
            v-model="selected.color"
            @input="onInput"
            :options="colors"
            :multiple="true"
            :exclusives="colors.slice(0,4)"
          ></FilterColorCheckboxes>
        </Collapser>
      </div>
      <div class="search-section">
        <Collapser>
          <div class="search-title" slot="header">
            Orientation
          </div>
          <FilterCheckboxes
            class="search-option"
            v-model="selected.orientation"
            @input="onInput"
            :multiple="true"
            :options="orientations"
          ></FilterCheckboxes>
        </Collapser>
      </div>
      <!--
      <div class="search-section">
        <Collapser :precollapse="!selected.style">
          <div class="search-title" slot="header">
            Style
          </div>
          <FilterCheckboxes
            class="search-option"
            v-model="selected.style"
            @input="onInput"
            :multiple="true"
            :options="styles"
          ></FilterCheckboxes>
        </Collapser>
      </div>
      -->
      <!-- <div class="search-section">
        <Collapser :precollapse="!selected.location && !selected.artistLocation">
          <div class="search-title" slot="header">
            Location
          </div>
          <div class="input-group search-option">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <IconLocation/>
              </span>
            </div>
            <input type="text"
              class="form-control"
              placeholder="content location"
              v-model="selected.location"
              @change="onInput"
            >
          </div>
          <div class="input-group search-option">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <IconPerson/>
              </span>
            </div>
            <input type="text"
              class="form-control"
              placeholder="artist location"
              v-model="selected.artistLocation"
              @change="onInput"
            >
          </div>
        </Collapser>
      </div> -->
      <div class="search-section">
        <Collapser :precollapse="!selected.substrate">
          <div class="search-title" slot="header">
            Substrate
          </div>
          <FilterCheckboxes
            class="search-option"
            v-model="selected.substrate"
            @input="onInput"
            :multiple="true"
            :options="substrates"
          ></FilterCheckboxes>
        </Collapser>
      </div>
      <div class="search-section">
        <Collapser :precollapse="!selected.price">
          <div class="search-title" slot="header">
            Price
          </div>
          <FilterCheckboxes
            class="search-option"
            v-model="selected.price"
            @input="onInput"
            placeholder="Select"
            :multiple="true"
            :options="prices"
          ></FilterCheckboxes>
        </Collapser>
      </div>
      <div class="search-section">
        <Collapser :precollapse="!selected.minimum_size">
          <div class="search-title" slot="header">
            Image Resolution
          </div>
          <FilterCheckboxes
            class="search-option"
            v-model="selected.minimum_size"
            @input="onInput"
            placeholder="Select"
            :options="minimum_sizes"
            :show-labels="false"
          ></FilterCheckboxes>
        </Collapser>
      </div>
      <div class="search-section">
        <Collapser :precollapse="!selected.artcollection">
          <div class="search-title" slot="header">
            Collection
          </div>
          <MultiselectObjectsApi
            :key="collectionsKey"
            class="search-option"
            v-model="selected.artcollection"
            @input="onInput"
            :get-options="getCollections"
            track-by="id"
            label="name"
            :show-labels="false"
          />
        </Collapser>
      </div>
      <div class="search-section">
        <Collapser :precollapse="!selected.royalty_code">
          <div class="search-title" slot="header">
            Artist
          </div>
          <MultiselectObjectsApi
            class="search-option"
            v-model="selected.royalty_code"
            @input="onInput"
            :get-options="getArtists"
            track-by="royalty_code"
            label="name"
            :show-labels="false"
            :custom-label="debug ? customLabelArtist : undefined"
          />
        </Collapser>
      </div>
      <div class="search-section">
        <Collapser :precollapse="!selected.source">
          <div class="search-title" slot="header">
            Publisher
          </div>
          <FilterCheckboxes
            class="search-option"
            v-model="selected.source"
            @input="onInput"
            track-by="value"
            label="label"
            :options="sources"
            :multiple="true"
          ></FilterCheckboxes>
        </Collapser>
      </div>
      <div class="search-section"
        v-if="hasApi && isStaff"
      >
        <Collapser :precollapse="!selected.api">
          <div class="search-title" slot="header">
            API
          </div>
          <FilterCheckboxes
            class="search-option"
            v-model="selected.api"
            @input="onInput"
            track-by="value"
            label="label"
            :options="apis"
          ></FilterCheckboxes>
        </Collapser>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Collapser from '@/components/Collapser.vue'
import FilterColorCheckboxes from '@/components/FilterColorCheckboxes.vue'
import FilterCheckboxes from '@/components/FilterCheckboxes.vue'
import MultiselectObjectsApi from '@/components/MultiselectObjectsApi.vue'
import SearchShowAllApis from '@/components/SearchShowAllApis.vue'
import { customLabelArtist } from '@/components/UploadForm.vue'
import { http } from '@/services/http.js'


export default {
  name: 'SearchFilters',
  components: {
    Collapser,
    FilterColorCheckboxes,
    FilterCheckboxes,
    MultiselectObjectsApi,
    SearchShowAllApis,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        debug: null,
        favorites: null,
        people: null,
        image_type: null,
        style: null,
        location: null,
        artistLocation: null,
        color: null,
        substrate: null,
        minimum_size: null,
        price: null,
        artist: null,
        source: null,
        orientation: null,
        api: null,
      }),
    },
    facets: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      selected: {},
      peoples: [
        { label: 'No People', value: 'false' },
        { label: 'With People', value: 'true' },
      ],
      image_types: [
        {value: 'fine art', label: 'Fine Art'},
        {value: 'photography', label: 'Photography'},
      ],
      styles: [
        'full frame',
        'close-up',
        'portrait',
        'sparse',
        'abstract',
        'macro',
        'still life',
        'cut out',
        'copy space',
      ],
      colors: [
        // keep in sync with colors list in:
        // https://gitlab.com/damon_c/gapco-search/blob/master/project/constants.py

        {value: 'black and white', label: 'Black & White'},
        {value: 'neutral', label: 'Neutral'},
        {value: 'pastel', label: 'Pastel'},
        {value: 'pale', label: 'Pale'},

        {value: 'red', label: 'red'},
        {value: 'orange', label: 'orange'},
        {value: 'yellow', label: 'yellow'},
        {value: 'green', label: 'limegreen'},
        {value: 'blue', label: 'blue'},
        {value: 'purple', label: 'purple'},
        {value: 'pink', label: 'pink'},
        {value: 'white', label: 'white'},
        {value: 'grey', label: 'grey'},
        {value: 'black', label: 'black'},
        {value: 'brown', label: 'sienna'},
        {value: 'gold', label: 'goldenrod'},
        {value: 'cream', label: 'oldlace'},
        {value: 'beige', label: 'wheat'},
        {value: 'tan', label: 'tan'},
      ],
      substrates: [
        {value: 'print', label: 'Print'},
        {value: 'canvas', label: 'Canvas'},
        {value: 'aluminum', label: 'Aluminum'},
        {value: 'acrylic', label: 'Acrylic'},
        {value: 'acoustic panel', label: 'Acoustic Panel'},
        {value: 'mural', label: 'Mural'},
        {value: 'window film', label: 'Window Film'},
      ],
      minimum_sizes: [
        {value: null, label: 'All'},
        {value: 'large', label: '3 MP and larger'},
        {value: 'x_large', label: '12 MP and larger'},
        {value: 'xx_large', label: '16 MP and larger'}
      ],
      prices: [
        {value: '1', label: '$'},
        {value: '2', label: '$$'},
        {value: '3', label: '$$$'},
        {value: '4', label: '$$$$'},
      ],
      sources: [
        {value: 'GAA', label:'Great American Art'},
        {value: 'BJOR', label:'BJOR'},
        {value: 'DOM', label:'DOM'},
        {value: 'WAG', label:'WAG'},
        {value: 'MCG', label:'MCG'},
        {value: 'BR', label:'BR'},
        {value: 'GI', label:'GI'},
        {value: 'POE', label:'POE'},
        {value: 'BUR', label:'BUR'},
        {value: 'PIN', label:'PIN'},
        {value: 'GET', label:'Getty'},
      ],
      orientations: [
        {value: 'vertical', label: 'Vertical'},
        {value: 'horizontal', label: 'Horizontal'},
        {value: 'square', label: 'Square'},
        {value: 'panoramic', label: 'Panoramic'},
      ],
      apis: [
        {value: null, label:'Internal + Getty'},
        {value: 'internal', label:'Internal'},
        {value: 'getty', label:'Getty'},
        {value: '', label:'Internal w/ indexed Getty'},
      ],
      hasApi: this.value.api !== undefined,
      collectionsKey: 0,
    }
  },
  computed: {
    ...mapState('user', [
      'user',
    ]),
    ...mapGetters('user', [
      'isStaff',
    ]),
    debug() {
      return Boolean(Number(this.value?.debug))
    },
  },
  watch: {
    facets: {
      handler(newValue) {
        this.sources = newValue.source;
        // TODO: would be better not to set selected here, but works for now
        this.selected = this.formatInput(this.value)
      },
      immediate: true,
    },
    value: {
      handler(newValue) {
        this.selected = this.formatInput(newValue)
      },
      immediate: true,
    },
    '$store.state.cache.collections'() {
      this.collectionsKey = Symbol()
    },
  },
  methods: {
    customLabelArtist,
    onInput() {
      this.$emit('input', this.formatOutput(this.selected))
    },
    formatInput(val) {
      // the input `value` will come in from the query string
      // select the matching values from the options arrays
      const result = {}
      for (let prop in val) {
        let item = val[prop]
        if (Array.isArray(item)) {
          item = item.map(i => findItem(this[prop+'s'], i))
        } else {
          item = findItem(this[prop+'s'], item)
        }
        result[prop] = item
      }
      return result
    },
    formatOutput(val) {
      // make into query object
      const result = {}
      for (let prop in val) {
        let item = val[prop]
        if (Array.isArray(item)) {
          if (!item.length) { continue }
          item = item.map(getValue)
        } else {
          item = getValue(item)
        }
        if (item === undefined || item === null) { continue }
        result[prop] = item
      }
      return result
    },
    getArtists(config) {
      const active = this.debug ? undefined : true
      return http.get('/api/artist/', {...config, params: {active, limit: 1000, ...config?.params}})
        .then(response => response.data)
    },
    getCollections(config) {
      return http.get('/api/collections/', {...config, params: {limit: 1000, ordering: 'name', ...config?.params}})
        .then(response => response.data)
    },
  },
}

function findItem(items, val) {
  if (!items) { return val }
  return items.find(i => {
    return i.value === val || i === val
  })
}

function getValue(val) {
  if (val === undefined || val === null) { return val }
  return (Object.hasOwnProperty.call(val, 'value')) ? val.value : val
}
</script>

<style lang="scss" scoped>
  .icon {
    fill: white;
  }
  .heart {
    transform: scale(1.2);
    margin-left: 1px;
  }
  .search-section {
    padding-bottom: 10px;
    border-bottom: 1px #666 solid;
  }
  .search-title {
    font-size: 1.1em;
    line-height: 1;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .search-option {
    padding-bottom: 10px;
  }
</style>
