import { mergeWith } from 'lodash'

export function splitAdapterData(settled, params) {
  const responses = settled.filter(i => i.value ).map(i => i.value )
  const errors    = settled.filter(i => i.reason).map(i => i.reason)
  const errorsToShow = errors
    // Suppress result window errors from internal, when the offset is greater than MAX_RESULTS
    .filter(i => i?.response?.[0]?.startsWith?.("TransportError(500, 'search_phase_execution_exception', 'Result window is too large"))
    // Suppress invalid page errors from getty, which happen when there are more internal results than getty results
    // FIXME: it would be better not to make the requests when offset is greater than total
    .filter(i => i?.response?.data?.ErrorCode !== 'InvalidPage')

  if (errors.length === settled.length) {
    throw errors[0]
  }

  const images = responses.flatMap(i => i.data.images)

  const totals = responses.map(i => i.data.total)
  const totalsSum = totals.reduce((a, b) => a + b, 0)
  const total = (totalsSum <= params.limit) ? images.length : totalsSum

  const totalAvailables = responses.map(i => i.data.total_available)
  const totalAvailablesSum = totalAvailables.reduce((a, b) => a + b, 0)
  const totalAvailable = Math.min(total, totalAvailablesSum)

  const combined = {
    images: images,
    total: total,
    total_available: totalAvailable,
    total_for_pagination: Math.max(...totalAvailables) * settled.length,
    total_is_partial: errors.length > 0,
    facets: mergeWith({}, ...responses.map(i => i.data.facets), concatArrays),
    errors: (errorsToShow.length) ? errorsToShow : undefined,
  }
  return {...responses[0].data, ...combined}
}

function concatArrays(objValue, srcValue) {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}
